import React from "react";
import "../App.css";

import MapLeaflet from "../Components/MapLeaflet";
import MapExample from "./MapExample";
import OfflineMap from "./OfflineMap";
import Map from "./Map";

function Home() {
  return (
    <div className="mainContainer">
      <div className="secondSubContainer">
        {/* <MapLeaflet /> */}
        <MapExample />
        {/* <OfflineMap /> */}
        {/* <Map/> */}
      </div>
    </div>
  );
}

export default Home;
