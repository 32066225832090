import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import "./Map.css";


const Pump = new L.Icon({
  iconUrl: "https://i.postimg.cc/zBM4HJdT/image-2-removebg-preview-3.png",
  // "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [58, 58],
  iconAnchor: [20, 38],
  popupAnchor: [1, -34],
  className: "custom-icon",
  iconColor: "red",
});
const PumpInActive = new L.Icon({
  iconUrl: "https://i.postimg.cc/rFWsLdN0/image-4.png",
  // "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [60, 60],
  iconAnchor: [20, 38],
  popupAnchor: [1, -34],
  className: "custom-icon",
  iconColor: "red",
});

const MapExample = () => {
  return (
    <MapContainer
      center={[37.745883, -122.429224]}
      zoom={12.5}
      style={{ height: "50vh", borderRadius: "1rem" }}
    >
      <TileLayer
        attribution="Google Maps"
        style={{ backgroundColor: "red" }}
        url=
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
        // "https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
      />

      <Marker
        position={[37.759493, -122.487204]}
        icon={PumpInActive}
        eventHandlers={{
          click: (e) => {
            window.location.href = "http://35.209.196.123/Main/index.html";
           
          },
        }}
      >
        {/* <Tooltip className="bold-text"></Tooltip> */}
      </Marker>
      <Marker position={[37.762644, -122.450241]} icon={Pump}>
        {/* <Tooltip className="bold-text"></Tooltip> */}
      </Marker>
      <Marker position={[37.734708, -122.485029]} icon={Pump}>
        {/* <Tooltip className="bold-text"></Tooltip> */}
      </Marker>
      <Marker position={[37.769687, -122.429238]} icon={Pump}>
        {/* <Tooltip className="bold-text"></Tooltip> */}
      </Marker>
      <Marker position={[37.746425, -122.434292]} icon={Pump}>
        {/* <Tooltip className="bold-text"></Tooltip> */}
      </Marker>
    </MapContainer>
  );
};

export default MapExample;
