import React, { useState } from "react";
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Tooltip,
} from "react-leaflet";
import L from "leaflet";
import customIconImage from "../assets/Plant_marker.png";
import markerIcon from "../assets/Selected_photo-removebg-preview.png";
import waterTreatement from "../assets/WaterTreatmentPlant.png";
import OHR from "../assets/OverheadReservoir.png";
import pumpingStation from "../assets/PumpingStation.png";
import "./Map.css";
const customIcon = new L.Icon({
  iconUrl: customIconImage,
  iconSize: [40, 64],
  iconAnchor: [22, 32],
  className: "marker-icon",
});
const defaultIcon = new L.Icon({
  iconUrl: markerIcon,
  // "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [40, 40],
  iconAnchor: [20, 38],
  popupAnchor: [1, -34],
  className: "custom-icon",
  iconColor: "red",
});
const pumpingStationIcon = new L.Icon({
  iconUrl: 'https://i.postimg.cc/W1NmD7sP/Pumping-Station.png',
  // "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [40, 40],
  iconAnchor: [20, 38],
  popupAnchor: [1, -34],
  className: "custom-icon",
  iconColor: "red",
});
const waterTreatmentIcon = new L.Icon({
  iconUrl: 'https://i.postimg.cc/XYsf9yvB/Water-Treatment-Plant.png',
  // "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [40, 40],
  iconAnchor: [20, 38],
  popupAnchor: [1, -34],
  className: "custom-icon",
  iconColor: "red",
});
const OHRIcon = new L.Icon({
  iconUrl: 'https://i.postimg.cc/hPbbPYWr/Overhead-Reservoir.png',
  // "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [40, 40],
  iconAnchor: [20, 38],
  popupAnchor: [1, -34],
  className: "custom-icon",
  iconColor: "red",
});
const tooltipStyle = {
  fontWeight: "bold",
  fontSize: "14px",
};

const App = () => {
  const defaultPolylineOptions = { color: "#3366ff" };
  const highlightedPolylineOptions = { color: "red" };
  const [highlightedPolyline, setHighlightedPolyline] = React.useState(null);
  
  return (
    <MapContainer
      center={[37.743858, -122.393364]}
      zoom={15.49}
      style={{ height: "100vh", borderRadius: "1rem" }}
    >
      <TileLayer
        attribution="Google Maps"
        style={{ backgroundColor: "red" }}
        url=
        // "https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Polyline
        positions={[
          [37.74675946797224, -122.39202018854755],
          [37.746038, -122.392267],
          [37.745733, -122.39158],
          [37.745326, -122.391977],
          [37.744736, -122.392533],
          [37.743858, -122.393364],
          [37.743826, -122.392632],
          [37.743519, -122.392083],
          [37.743085, -122.391351],
          [37.74276, -122.391566],
          [37.742231, -122.392023],
          [37.74128, -122.392845],
          [37.74091, -122.392219],
          [37.740482, -122.392393],
          [37.739897, -122.39132],
          [37.739871, -122.391277],
          [37.738887, -122.389518],
          [37.738189, -122.388359],
        ]}
        // color="#11437c"
        stroke={true}
        weight={5}
        pathOptions={
          highlightedPolyline === "polyline1"
            ? highlightedPolylineOptions
            : defaultPolylineOptions
        }
        eventHandlers={{
          mouseover: () => setHighlightedPolyline("polyline1"),
          mouseout: () => setHighlightedPolyline(null),
        }}
      >
        <Tooltip sticky>
          <div className="bold-text">
            Pipeline Material: Concrete
            <br />
            Length of Pipe: 6.8 km
            <br />
            Flow Rate: 23.15 GPM
            <br />
            Pressure: 4.3 bar
            <br />
            DOI: 1995
          </div>
        </Tooltip>
      </Polyline>
      <Polyline
        positions={[
          [37.743074, -122.4053],
          [37.742323, -122.403943],
          [37.741568, -122.402612],
          [37.741084, -122.401502],
          [37.742217, -122.400435],
          [37.741572, -122.399329],
          [37.741076, -122.398455],
          [37.740168, -122.396797],
          [37.740491, -122.396395],
          [37.741899, -122.394936],
          // [37.739888, -122.391288],
          [37.740482, -122.392393],
        ]}
        stroke={true}
        weight={5}
        // color="#11437c"
        pathOptions={
          highlightedPolyline === "polyline2"
            ? highlightedPolylineOptions
            : defaultPolylineOptions
        }
        eventHandlers={{
          mouseover: () => setHighlightedPolyline("polyline2"),
          mouseout: () => setHighlightedPolyline(null),
        }}
      >
        <Tooltip className="bold-text">
          <div className="bold-text">
            Pipeline Material: HDPE
            <br />
            Length of Pipe: 6.3 km
            <br />
            Flow Rate: 44.73 GPM
            <br />
            Pressure: 5.3 bar
            <br />
            DOI: 2003
          </div>
        </Tooltip>
      </Polyline>
      <Polyline
        positions={[
          [37.738887, -122.389518],
          [37.738026, -122.389539],
          [37.737249, -122.389802],
          [37.736569, -122.390044],
          [37.736108, -122.390197],
          [37.735446, -122.390438],
          [37.73481, -122.39065],
          [37.734146, -122.390902],
        ]}
        // color="#11437c"
        stroke={true}
        weight={5}
        pathOptions={
          highlightedPolyline === "polyline3"
            ? highlightedPolylineOptions
            : defaultPolylineOptions
        }
        eventHandlers={{
          mouseover: () => setHighlightedPolyline("polyline3"),
          mouseout: () => setHighlightedPolyline(null),
        }}
      >
        <Tooltip>
          <div className="bold-text">
            Pipeline Material: PVC
            <br />
            Length of Pipe: 2.0 km
            <br />
            Flow Rate: 39.84 GPM
            <br />
            Pressure: 5.0 bar
            <br />
            DOI: 2001
          </div>
        </Tooltip>
      </Polyline>
      <Polyline
        positions={[
          [37.74675946797224, -122.39202018854755],
          [37.746038, -122.392267],
          [37.745733, -122.39158],
          [37.745326, -122.391977],
          [37.745148, -122.392243],
          [37.745623, -122.393114],
          [37.746028, -122.39386],
          [37.746422, -122.394506],
          [37.747065, -122.395751],
          [37.747165, -122.396877],
          [37.747116, -122.398127],
          [37.747004, -122.398921],
          [37.746639, -122.400112],
          [37.746276, -122.401265],
        ]}
        // color="#11437c"
        stroke={true}
        weight={5}
        pathOptions={
          highlightedPolyline === "polyline4"
            ? highlightedPolylineOptions
            : defaultPolylineOptions
        }
        eventHandlers={{
          mouseover: () => setHighlightedPolyline("polyline4"),
          mouseout: () => setHighlightedPolyline(null),
        }}
      >
        <Tooltip className="bold-text">
          <div className="bold-text">
            Pipeline Material: Ductile Iron
            <br />
            Length of Pipe: 5.1 km
            <br />
            Flow Rate: 37.74 GPM
            <br />
            Pressure: 5.2 bar
            <br />
            DOI: 1998
          </div>
        </Tooltip>
      </Polyline>
      <Polyline
        positions={[
          [37.747065, -122.395751],
          [37.748448, -122.396663],
          [37.749721, -122.397398],
          [37.749814, -122.395456],
          [37.749915, -122.393503],
          [37.750094, -122.392597],
        ]}
        // color="#11437c"
        stroke={true}
        weight={5}
        pathOptions={
          highlightedPolyline === "polyline5"
            ? highlightedPolylineOptions
            : defaultPolylineOptions
        }
        eventHandlers={{
          mouseover: () => setHighlightedPolyline("polyline5"),
          mouseout: () => setHighlightedPolyline(null),
        }}
      >
        <Tooltip sticky className="bold-text">
          <div className="bold-text">
            Pipeline Material: PVC <br />
            Length of Pipe: 2.5 km <br />
            Flow Rate: 31.94 GPM
            <br />
            Pressure: 4.5 bar
            <br />
            DOI: 2003
          </div>
        </Tooltip>
      </Polyline>
      <Polyline
        positions={[
          [37.745733, -122.39158],
          [37.744732, -122.389831],
          [37.743637, -122.387954],
          [37.744944, -122.38717],
          [37.746115, -122.387128],
          [37.74564, -122.386216],
          [37.743968, -122.383136],
          [37.742467, -122.380755],
        ]}
        // color="#11437c"
        stroke={true}
        weight={5}
        pathOptions={
          highlightedPolyline === "polyline6"
            ? highlightedPolylineOptions
            : defaultPolylineOptions
        }
        eventHandlers={{
          mouseover: () => setHighlightedPolyline("polyline6"),
          mouseout: () => setHighlightedPolyline(null),
        }}
      >
        <Tooltip className="bold-text">
          <div className="bold-text">
            Pipeline Material: Steel
            <br /> Length of Pipe: 6.2 km <br />
            Flow Rate: 34.72 GPM
            <br />
            Pressure: 4.6 bar
            <br />
            DOI: 1999
          </div>
        </Tooltip>
      </Polyline>
      <Polyline
        positions={[
          [37.747065, -122.395751],
          [37.748448, -122.396663],
          [37.749721, -122.397398],
          [37.749483, -122.401024],
          [37.749644, -122.402837],
          [37.749296, -122.404178],
          [37.748957, -122.406367],
        ]}
        // color="#11437c"
        stroke={true}
        weight={5}
        pathOptions={
          highlightedPolyline === "polyline7"
            ? highlightedPolylineOptions
            : defaultPolylineOptions
        }
        eventHandlers={{
          mouseover: () => setHighlightedPolyline("polyline7"),
          mouseout: () => setHighlightedPolyline(null),
        }}
      >
        <Tooltip className="bold-text">
          <div className="bold-text">
            Pipeline Material: HDPE <br />
            Length of Pipe: 4.2 km <br />
            Flow Rate: 37.74 MLD
            <br />
            Pressure: 4.8 bar
            <br />
            DOI: 2004
          </div>
        </Tooltip>
      </Polyline>
      <Polyline
        positions={[
          [37.741076, -122.398455],
          [37.739973, -122.399764],
          [37.73932, -122.402328],
          [37.738336, -122.405568],
          [37.738051, -122.406813],
          [37.737907, -122.407456],
        ]}
        // color="#11437c"
        stroke={true}
        weight={5}
        pathOptions={
          highlightedPolyline === "polyline8"
            ? highlightedPolylineOptions
            : defaultPolylineOptions
        }
        eventHandlers={{
          mouseover: () => setHighlightedPolyline("polyline8"),
          mouseout: () => setHighlightedPolyline(null),
        }}
      >
        <Tooltip className="bold-text">
          <div className="bold-text">
            Pipeline Material: Ductile Iron
            <br />
            Length of Pipe: 2.9 km
            <br />
            Flow Rate: 36.95 GPM
            <br />
            Pressure: 4.7 bar
            <br />
            DOI: 1993
          </div>
        </Tooltip>
      </Polyline>
      <Marker
        position={[37.74675946797224, -122.39202018854755]}
        className="marker-icon"
        icon={waterTreatmentIcon}
      >
        <Tooltip className="bold-text-head">
          {`South-East Water Treatment Plant`}
          <br />
          Capacity :90 MLD
        </Tooltip>
      </Marker>
      <Marker position={[37.750094, -122.392597]} icon={pumpingStationIcon}>
        <Tooltip className="bold-text">
          {`Potero Hill Pumping Station `}
          <br />
          <span>
            <span className="square-icon"> </span> Active :{" "}
          </span>
          <span>5</span>
          <br />
          <span>
            <span className="square-icon-red"> </span> Inactive :{" "}
          </span>
          <span>2</span>
        </Tooltip>
      </Marker>
      <Marker position={[37.738189, -122.388359]} icon={pumpingStationIcon}>
        <Tooltip className="bold-text">
          {`Bay View Pumping Station`}
          <br />
          <span>
            <span className="square-icon"> </span> Active :{" "}
          </span>
          <span>3</span>
          <br />
          <span>
            <span className="square-icon-red"> </span> Inactive :{" "}
          </span>
          <span>3</span>
        </Tooltip>
      </Marker>
      <Marker position={[37.746276, -122.401265]} icon={OHRIcon}>
        <Tooltip className="bold-text">
          {`Produce Market OHR `}
          <br />
          Capacity: 1 Milllion Liters
          <br />
          Current level: 3m
          <br />
          Max level: 5 m
        </Tooltip>
      </Marker>
      <Marker position={[37.734146, -122.390902]} icon={OHRIcon}>
        <Tooltip className="bold-text">
          {`BW OHR`}
          <br />
          Capacity: 1.4 Million Liters
          <br />
          Current level: 3.8m
          <br />
          Max level: 6.5 m
        </Tooltip>
      </Marker>
      <Marker position={[37.743074, -122.4053]} icon={OHRIcon}>
        <Tooltip className="bold-text">
          {`Apparel City OHR`}
          <br />
          Capacity: 1.2 Million Liters
          <br />
          Current level: 2.8m
          <br />
          Max level: 6 m
        </Tooltip>
      </Marker>
      <Marker position={[37.742467, -122.380755]} icon={pumpingStationIcon}>
        <Tooltip className="bold-text">
          {`Indian Basin Pumping Station`}
          <br />
          <span>
            <span className="square-icon"> </span> Active :{" "}
          </span>
          <span>4</span>
          <br />
          <span>
            <span className="square-icon-red"> </span> Inactive :{" "}
          </span>
          <span>2</span>
        </Tooltip>
      </Marker>
      <Marker position={[37.748957, -122.406367]} icon={OHRIcon}>
        <Tooltip className="bold-text">
          {`Mission District OHR`}
          <br />
          Capacity: 1.35 Million liters
          <br />
          Current level: 4m
          <br />
          Max level: 6 m
        </Tooltip>
      </Marker>
      <Marker position={[37.737907, -122.407456]} icon={pumpingStationIcon}>
        <Tooltip className="bold-text">
          {`AC Pumping Station`}
          <br />
          <span>
            <span className="square-icon"> </span> Active :{" "}
          </span>
          <span>4</span>
          <br />
          <span>
            <span className="square-icon-red"> </span> Inactive :{" "}
          </span>
          <span>3</span>
        </Tooltip>
      </Marker>
      Collapse has context menu{" "}
    </MapContainer>
  );
};

export default App;
